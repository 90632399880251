import * as React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { FC } from 'react';
import { ROUTES } from '../../app.router/ROUTES'

interface IHeaderProps {
  linkAddress: string;
  linkText?: string;
  logoColor: string;
}

const Header:FC<IHeaderProps> = ({
                                   linkAddress,
                                   linkText,
                                   logoColor
                                 }) => {

  return (
    <div className="header">
      <div className="header-link">
        <Link to={linkAddress} className="header-link-text">
          <img src={process.env.PUBLIC_URL + '/assets/misc/arrow-left.svg'} alt="arrow left" className="header-link-icon" />
          {linkText}
        </Link>
      </div>
      <Link to={`${ROUTES.HOME}`}>
        {logoColor === 'blue' ? (
          <img src={'/assets/logos/logo-primary.svg'} alt="Cabana logo" className="header-logo" />
        ) : (
          <img src={'/assets/logos/logo-white.svg'} alt="Cabana logo" className="header-logo" />
        )}
      </Link>
    </div>
  );
}

export default Header;
