import './ButtonAction.css';
import CN from 'classnames';
import { FC } from 'react';

interface IButtonProps {
  text: string;
  onClickHandler?: () => void;
  filled?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
}

const ButtonAction: FC<IButtonProps> = ({
                                       text,
                                       onClickHandler,
                                       filled,
                                       outlined,
                                       disabled,
                                       hidden,
                                       className,
                                     }) => {
  return (
    <button
      type="button"
      className={CN(
        'button',
        { 'button-filled': filled },
        { 'button-filled-disabled': disabled },
        { 'button-filled-hidden': hidden },
        { 'button-outlined': outlined },
        { 'button-am-ready': className === 'button-am-ready' },
      )}
      onClick={onClickHandler}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default ButtonAction;
