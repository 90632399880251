import { Link } from 'react-router-dom';
import './ButtonLink.css';
import CN from 'classnames';
import { FC } from 'react';

interface IButtonProps {
  text: string;
  linkAddress?: string;
  onClickHandler?: () => void;
  filled?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
}

const ButtonLink:FC<IButtonProps> = ({
                                  text,
                                  linkAddress,
                                  onClickHandler,
                                  filled,
                                  outlined,
                                  disabled,
                                  hidden,
                                  className,
                                }) => {
  return (
    <Link to={linkAddress as string}>
      <button
        type="button"
        className={CN(
          'button',
          { 'button-filled': filled },
          { 'button-filled-disabled': disabled },
          { 'button-filled-hidden': hidden },
          { 'button-outlined': outlined },
          { 'button-am-ready': className === 'button-am-ready' },
        )}
        onClick={onClickHandler}
        disabled={disabled}
      >
        {text}
      </button>
    </Link>
  );
}

export default ButtonLink;
