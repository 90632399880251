import * as React from 'react';
import './FaqButton.css';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../app.router/ROUTES'
import {FC} from 'react';

interface IFaqButtonProps {
  color: string;
}

const FaqButton: FC<IFaqButtonProps> = ({color}) => {
  return (
    <div className="faq-button-container">
      <Link to={`${ROUTES.FAQ}`}>
        {(color === 'grey') && (<img src={'/assets/faq-buttons/faq-button-grey.svg'} alt="faq" className="faq-button-icon" />)}
        {(color === 'white') && (<img src={'/assets/faq-buttons/faq-button-white.svg'} alt="faq" className="faq-button-icon" />)}
        {(color === 'black') && (<img src={'/assets/faq-buttons/faq-button-black.svg'} alt="faq" className="faq-button-icon" />)}
      </Link>
    </div>
  );
}

export default FaqButton;
