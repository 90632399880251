import React, {useContext, useEffect} from 'react';
import { motion } from 'framer-motion';
import './StartPage.css';
import { ROUTES } from '../../app.router/ROUTES'
import Button from '../../components/Button.Library/Button.Link/ButtonLink';
import { Context } from '../../Context';
import FaqButton from '../../components/FaqButton/FaqButton';

const StartPage = () => {
  const { pageVariants, pageTransition, setDisclaimerOpened, setPreviousPage }: any = useContext(Context);

  useEffect(() => {
    setPreviousPage('/');
  }, []);

  return (
    <motion.div
      className="start-page"
      initial="in"
      animate="out"
      exit="exit"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="start-page-box">
        <img src={process.env.PUBLIC_URL + '/assets/logos/logo-white.svg'} alt="logo" className="start-page-logo" />
        <h1 className="start-page-title">
          Relax with a
          <br />
          micro-vacation
        </h1>
        <Button text="Start" filled linkAddress={`${ROUTES.DISCLAIMER}`} onClickHandler={() => setDisclaimerOpened(true)}/>
      </div>
      <FaqButton color="black"/>
    </motion.div>
  );
}

export default StartPage;
