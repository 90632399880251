import { createContext, Dispatch } from 'react';
export interface IStore {
  disclaimerOpened: boolean,
  setDisclaimerOpened: Dispatch<boolean>,
  feelingValue: string,
  setFeelingValue: Dispatch<string>,
  sceneSelected: string,
  setSceneSelected: Dispatch<string>,
  audioSelected: string,
  setAudioSelected: Dispatch<string>,
  deviceSelected: string,
  setDeviceSelected: Dispatch<string>,
  pageVariants: any,
  pageTransition: any,
  scenesList: any,
  setScenesLst: Dispatch<any>,
  activeScene: any,
  setActiveScene: Dispatch<any>,
  previousPage: string,
  setPreviousPage: Dispatch<any>,
}

export const Context = createContext<IStore | null>(null);
