import React, { useContext, useEffect } from 'react';
import { motion } from 'framer-motion';
import CN from 'classnames';
import { Context } from '../../Context';
import './MeditationSelectionPage.css';
import Header from '../../components/Header/Header';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import FaqButton from '../../components/FaqButton/FaqButton';
import { ROUTES } from '../../app.router/ROUTES';
import { audios } from '../../constants/app.config';
import { useNavigate } from 'react-router-dom';
import ButtonAction from '../../components/Button.Library/Button.Action/ButtonAction';

const MeditationSelectionPage = () => {
  const navigate = useNavigate();

  const {
    scenesList,
    activeScene,
    setActiveScene,
    audioSelected,
    setAudioSelected,
    pageTransition,
    pageVariants,
    deviceSelected,
    setDeviceSelected,
    setPreviousPage
  }: any = useContext(Context);

  const randomAudio = () => {
    const randomIndex = Math.floor(Math.random() * audios.length);
    setAudioSelected(audios[randomIndex]);
    if (deviceSelected === 'vr') {
      navigate(`${ROUTES.VR_VIEWER}?&scene=${activeScene?.title}&audio=${audios[randomIndex].id}`);
    } else {
      navigate(`${ROUTES.VIEWER}?&scene=${activeScene?.title}&audio=${audios[randomIndex].id}`);
    }
  };

  const nextButtonHandler = () => {
    if (deviceSelected === 'tablet') {
      navigate(`${ROUTES.VIEWER}?&scene=${activeScene?.title}&audio=${audioSelected.id}`)
    } else if (deviceSelected === 'vr') {
      navigate(`${ROUTES.VR_VIEWER}?&scene=${activeScene?.title}&audio=${audioSelected.id}`)
    }
  }

  useEffect(() => {
    if (window.location.search.length) {
      const query = new URLSearchParams(window.location.search);

      const deviceParam = query.get('device');
      if (deviceParam && deviceParam.toUpperCase() !== deviceSelected.toUpperCase()) {
        setDeviceSelected(deviceParam);
      }
      const sceneParam = query.get('scene');
      if (sceneParam && sceneParam.toUpperCase() !== activeScene?.title.toUpperCase()) {
        const sceneObj = scenesList.find((el: any) => el.title.toUpperCase() === sceneParam.toUpperCase());
        if (sceneObj) {
          setActiveScene(sceneObj);
        } else {
          setActiveScene(scenesList[0]);
        }
      }

      const audioParam = query.get('audio');
      if (audioParam && audioParam.toUpperCase() !== audioSelected?.id?.toUpperCase()) {
        const audioObj = audios.find((el: any) => el.id.toUpperCase() === audioParam.toUpperCase());
        if (audioObj) {
          setAudioSelected(audioObj);
        } else {
          setAudioSelected(audios[0]);
        }
      }
    }
  }, [window.location.search]);

  useEffect(() => {
    setPreviousPage(`${ROUTES.MEDITATION_AUDIO}`);
  }, []);

  return (
    <>
      <motion.div
        className="meditation-page"
        initial="in"
        animate="out"
        exit="exit"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Header linkAddress={`${ROUTES.SCENERY}?&device=${deviceSelected}`} linkText="Back" logoColor="blue"/>
        <div className="meditation-page-container">
          <ProgressBar
            image={<img src={'/assets/progress-bars/progress_line_step_4.svg'} alt="progress bar"
                        className="progressbar-image"/>}
          />
          <h2 className="meditation-page-title">
            Select audio experience
          </h2>
          <div className="meditation-audio-container">

            {audios
              .sort((a, b) => a.order - b.order)
              .map((audio, index) => (
                <div
                  className={CN(
                    'meditation-audio-image',
                    { 'meditation-audio-item-selected': audioSelected.id === `${audio.id}` },
                    {
                      'meditation-audio-item-unselected':
                        audioSelected && audioSelected.id !== `${audio.id}`,
                    },
                  )}
                  onClick={() => setAudioSelected(audio)}
                  key={audio.name + index}
                >
                  <h3 className="meditation-audio-image-title">
                    {audio.duration}
                    < br/>
                    {audio.name}
                  </h3>
                </div>
              ))}

          </div>
          <div className="meditation-buttons-container">
            <ButtonAction
              text="Surprise me"
              outlined
              onClickHandler={() => randomAudio()}
            />
            <ButtonAction
              text="Next"
              filled
              onClickHandler={nextButtonHandler}
              disabled={!audioSelected}
            />
          </div>
        </div>
      </motion.div>
      <FaqButton color="grey"/>
    </>
  );
}

export default MeditationSelectionPage;
