import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import './Countdown.css';
import CN from 'classnames';

interface ICountdownProps {
  isReady?: boolean;
}

const Countdown:FC<ICountdownProps> = ({isReady}) => {
  const [timeLeft, setTimeLeft] = useState(5);
  const start = Date.now();

  useEffect(() => {
    if (isReady) {
      setInterval(() => {
        let delta = Date.now() - start;
        setTimeLeft(timeLeft - Math.floor(delta / 1000));
      }, 1000);
    }
  }, []);

  return (
    <div className="base-timer">
      <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g className="base-timer__circle">
          <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"/>
          <path
            id="base-timer-path-remaining"
            strokeDasharray="283"
            className={CN(
              'base-timer__path-remaining',
              {'base-timer__path-remaining-countdown-off': !isReady},
              {'base-timer__path-remaining-countdown-on': isReady},
            )}
            d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
          ></path>
        </g>
      </svg>
      <span id="base-timer-label" className="base-timer__label">
        {timeLeft}
      </span>
    </div>
  );
}

export default Countdown;
